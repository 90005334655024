import React, {useState} from 'react';
import './payment.css'


const Payment = () => {

    const [resultado, setResultado] = useState('Selecione Forma de Pagamento') 


    // Tem que ter um switch
    // toda vez que o valor muda renderiza uma seção com os conteúdos expecíficos


    return (
        <section className='grid-center'>

            <div id="payment__type-selector">
                <label><input type='radio' name='paymentType' value='pix' onChange={e => setResultado(e.target.value)}/> Pix</label>
                <label><input type='radio' name='paymentType' value='dinheiro' onChange={e => setResultado(e.target.value)}/> Dinheiro</label>
                <label><input type='radio' name='paymentType' value='crédito' onChange={e => setResultado(e.target.value)}/> Crédito</label>
                <label><input type='radio' name='paymentType' value='débito' onChange={e => setResultado(e.target.value)}/> Débito</label>
            </div>

            <div id="payment-content__conteiner">

                <h3>{resultado}</h3>
                {resultado === 'pix'&& <div><h3>Nome: </h3><h4>Pizzaria Porto 04</h4></div>}
                {resultado === 'pix'&& <div><h3>chave: </h3><h4>porto04@gmail.com</h4></div>}

                {resultado === 'dinheiro'&& <label name="troco">Troco para Quanto? <br/> R$ </label>}
                {resultado === 'dinheiro'&& <input type="number" name='troco' className="form-input"></input>}

                {resultado === 'crédito' && <h3>Pagar na Entrega</h3>}
                {resultado === 'débito' && <h3>Pagar na Entrega</h3>}

            </div>

        </section>
    )
}


export default Payment;