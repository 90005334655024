import React from 'react';
import { Link } from 'react-router-dom';
import './header.css'
import styled from 'styled-components';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

const WhatsappButton = styled.a`

    flex: 1 1;
    max-width: 100px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: var(--branco);
    text-transform: uppercase;
    text-decoration: none; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    border: 0px;
    border-radius: 25px;

    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    &:active {

        background-color: var(--vermelho-claro);

    }

    & > h4 {

        margin: 0px 5px;
        font-weight: 400;
    }

    @media(max-width: 425px) {

        font-size: 12px;
        padding: 10px 10px;
        max-width: 50px;

        & > h4 {
            display: none;
        }
    }
`

const Header = (props) => {
    
    
    const openStatus = props.status;

    return (

        <header className='grid-center inner-grid' id='app-header'>
            <div className="grid-center">
                <Link to='/'>
                    <img src={props.logo} alt='logo' id="header-logo" />
                </Link>
                <div className='status-content'>
                    {openStatus === false? <div className="open-status close"></div> : <div className="open-status open"></div> }
                    {openStatus === false? <h4 className="status-title">Fechado</h4> : <h4 className="status-title">Aberto</h4>}
                </div>
                {props.whatsapp !== '' && <WhatsappButton href={`http://api.whatsapp.com/send?phone=55${props.whatsapp}`} id="whatsappButton"><FontAwesomeIcon icon={faWhatsapp} size="lg" /><h4>WhatsApp</h4></WhatsappButton>}
                <Link to='/carrinho'>
                    <div id="order-link">
                        <FontAwesomeIcon icon={faShoppingBag} size='2x'/>
                        <h6>Pedido</h6>      
                    </div>
                </Link>
            </div>
        </header>

    )
}


export default Header;