import React, {useContext} from "react";

import { FetchContext } from '../../../App';
// import apiData from '../../../Fetch';

import '../order.css';

import { OrderContext } from "../Order";
import ItemCounter from "./ItemCounter";


const FlavorSelecter = () => {

    const allData = useContext(FetchContext);
    // const URLapi = apiData.urlAPI;
    const categorias = allData.categorias
    const pizzas = categorias.find((item) => {if(item.nome == "Pizzas") {return true} else {return false}})
    
    const orderCtx = useContext(OrderContext);

    if(pizzas !== undefined){

        return(

            <section id="flavor-selecter">
                <div className='order-section__title-container'>
                    <div>
                        <h3>Selecione o Sabor</h3>
                        <p>Escolha até 2 opções</p>
                    </div>
                    <h4>Obrigatório</h4>
                </div>
                <div className='order-section__selecter-container'>
                    {
                        pizzas.produtos.map((item, i) => {

                            return (

                                <article key={i} className=''>
                                    <div>
                                        <label className='selecter__description-price'>
                                            <h3>{item.nome}</h3>
                                            <p>{item.descricao}</p>
                                            <p className='price'>{orderCtx.size != 'grande'? item.tamanho[0].valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}): item.tamanho[1].valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                                        </label>
                                        <ItemCounter item={item.nome} price={orderCtx.size != 'grande'? item.tamanho[0].valor : item.tamanho[1].valor} counterType='pizza' name={`flavor${i}`} />
                                    </div>
                                    <hr />
                                </article>
                            )
                        })
                    }
                </div>
            </section>
            
        )

    } else {

        return(<></>)
        
    }
}


export default FlavorSelecter;