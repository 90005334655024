import React from 'react';
import './footer.css';
import logoArara from './logo-arara.png';
import logoFooter from './logo-footer.png';

const Footer = () => {

    return (
        <footer id="main-footer" className="grid-full">
            <img src={logoArara} alt="Logo arara" id="logo-arara"/>
            <img src={logoFooter} alt="Logo footer" id="logo-footer" />
        </footer>
    )
}

export default Footer;