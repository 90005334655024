import { createContext, useState, useEffect } from 'react'
import './App.css';
import {Switch, Route} from 'react-router-dom';
import MainPage from './components/mainPage/MainPage';
import FullItem from './components/fullItem/FullItem';
import Checkout from './components/checkout/Checkout';
import { Order } from './components/order-new/Order';

import apiData from './Fetch';

const FetchContext = createContext()

function App() {

  // Dados Referentes ao restaurante 

  const [data, setData] = useState('');
  const [bannerData, setBannerData] = useState('');
  const [logoData, setLogoData] = useState('');
  
  const [categorias, setCategorias] = useState([]);

  const [refreshOrder, setRefreshOrder] = useState(1);

  useEffect(() => {

    async function restaurantData() {

      var restaurantData = await apiData.retaurantData;
      setLogoData(restaurantData[0].logo);
      setData(restaurantData[0]);
      setBannerData(restaurantData[0].banner);

    }

    async function categoryData() {

      var categoryData = await apiData.categoryData
      setCategorias(categoryData);

    }

    restaurantData();
    categoryData()
    
  }, [])


  var allData = {

    data,
    bannerData,
    logoData,
    categorias,
    refreshOrder,
    setRefreshOrder

  }
  
  return (
    <FetchContext.Provider value={allData}>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/produto">
          <FullItem />
        </Route>
        <Route path="/carrinho">
          <Checkout />
        </Route>
        <Route path="/pedido">
          <Order />
        </Route>
      </Switch>
    </FetchContext.Provider>
);
}

export {App, FetchContext};
