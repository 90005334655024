import React, {useState, useEffect} from 'react';
import apiData from '../../Fetch';
import './products.css';
import Product from './product/product';

const Products = () => {

    const [categorias, setCategoria] = useState([]);
    const [promocoes] = useState([]);

    useEffect(() => {

        async function categoryData() {

            var categoryData = await apiData.categoryData
            setCategoria(categoryData);
      
        }

        categoryData()
        
    }, []);

    if(promocoes.length === 0) {

        return(

            <>
                {categorias.sort((a, b) => {return a.posicaoNoApp < b.posicaoNoApp ? -1 : a.posicaoNoApp > b.posicaoNoApp ? 1 : 0;}).map((categoria, i) => {

                    return(
                        <section className="grid-center" id={`${categoria.nome.replace(/\s/g, '')}`} key={i}>
                            <h2 className="product-section__title">{categoria.nome}</h2>
                                    
                            {categoria.produtos.map((item, i) => {
                                
                                if(item.tamanho[0].valorPromocional !== null) {

                                    let index = promocoes.findIndex(val => val.id === item.id);

                                    if(index < 0) {
                                        return promocoes.push(item);
                                    }
                                    
                                    return false;

                                } else {

                                    return (
                                    <Product 
                                    key = {item.id}
                                    itemID = {item.id}
                                    name = {item.nome}
                                    image = {item.imagem.url}
                                    description = {item.descricao}
                                    price = {item['tamanho'][0]['valor']}
                                    promotionalPrice = {item['tamanho'][0]['valorPromocional']}
                                    />)


                                }
                            })}
                        </section>
                    )}
                )}
            </>
        )
    
    } else {

        return (
            <>
                <section className="grid-center">
                    <h2 className="product-section__title">Promoção</h2>
    
                        {promocoes.map((item, i) => {
    
                                return(
    
                                    <Product 
                                        key = {i}
                                        itemID = {item.id}
                                        name = {item.nome}
                                        image = {item.imagem.url}
                                        description = {item.descricao}
                                        price = {item['tamanho'][0]['valor']}
                                        promotionalPrice = {item['tamanho'][0]['valorPromocional']}
                                    />
    
                                )
                            })
                        }
    
                </section>
    
                {
                    categorias.sort((a, b) => {return a.posicaoNoApp < b.posicaoNoApp ? -1 : a.posicaoNoApp > b.posicaoNoApp ? 1 : 0;}).map((categoria, i) => {
    
                        return(
                            <section className="grid-center" id={categoria.nome.replace(/\s/g, '')} key={i}>
                                <h2 className="product-section__title">{categoria.nome}</h2>
                                        
                                {categoria.produtos.map((item, i) => {
                                    
                                    if(item.tamanho[0].valorPromocional !== null) {
    
                                        let index = promocoes.findIndex(val => val.id === item.id);
    
                                        if(index < 0) {
                                            return promocoes.push(item);
                                        }
                                        
                                        return false;
    
                                    } else {
    
                                        return (
                                        <Product 
                                        key = {item.id}
                                        itemID = {item.id}
                                        name = {item.nome}
                                        image = {item.imagem.url}
                                        description = {item.descricao}
                                        price = {item['tamanho'][0]['valor']}
                                        promotionalPrice = {item['tamanho'][0]['valorPromocional']}
                                        />)
    
                                    }
                    
                                })}
                            </section>
                        )
                    })
                }
            </>
        )

    }


}

export default Products;