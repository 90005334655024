import React, {useContext} from "react";

import { FetchContext } from '../../../App';
// import apiData from '../../../Fetch';

import '../order.css';

import { OrderContext } from "../Order";


const SizeSelecter = () => {

    const allData = useContext(FetchContext);
    // const URLapi = apiData.urlAPI;
    const categorias = allData.categorias;
    const pizzas = categorias.find((item) => {if(item.nome == "Pizzas") {return true} else {return false}});

    const orderCtx = useContext(OrderContext);
    
    if(pizzas !== undefined) {

        return (

            <section id="size-section">
                <div className='order-section__title-container'>
                    <div>
                        <h3>Selecione o tamanho</h3>
                        <p>Escolha 1 opção</p>
                    </div>
                    <h4>Obrigatório</h4>
                </div>
                <div className='order-section__selecter-container'>
                    {
                        pizzas.tamanho.map((item, i) => {

                            return (
                                <article className='' key={i}>
                                    <div>
                                        <label className='selecter-container__description'>
                                            <h3>{item.nome}</h3>
                                            <p>{item.descricao}</p>
                                        </label>
                                        <input type='radio' name='size' value={item.nome} onClick={() => orderCtx.setSize(item.nome)} />
                                    </div>
                                    <hr />
                                </article>
                            )

                        })
                    }
                </div>
            </section>

        )
    }

    return (

        <section id="size-section">
            <div className='order-section__title-container'>
                <div>
                    <h3>Selecione o tamanho</h3>
                    <p>Escolha 1 opção</p>
                </div>
                <h4>Obrigatório</h4>
            </div>
            <div className='order-section__selecter-container'>
                <article className=''>
                    <div>
                        <label className='selecter-container__description'>
                            <h3>Pequena</h3>
                            <p>4 fatias</p>
                        </label>
                        <input type='radio' name='size' />
                    </div>
                    <hr />
                </article>
                <article className=''>
                    <div>
                        <label className='selecter-container__description'>
                            <h3>Média</h3>
                            <p>6 fatias</p>
                        </label>
                        <input type='radio' name='size' />
                    </div>
                    <hr />
                </article>
                <article className=''>
                    <div>
                        <label className='selecter-container__description'>
                            <h3>Grande</h3>
                            <p>8 fatias</p>
                        </label>
                        <input type='radio' name='size' />
                    </div>
                </article>
            </div>
        </section>

    )
}


export default SizeSelecter;