import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { FetchContext } from '../../App';
import './fullItem.css';
import apiData from '../../Fetch';

import Header from '../header/Header';
import Menu from '../menu/Menu';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

import styled from 'styled-components';


const OrderButtom = styled.a`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    border: 0px;
    border-radius: 25px;
    justify-self: flex-start;

    &:active {

    background-color: var(--vermelho-claro);
}

`


const FullItem = () => {

    const allData = useContext(FetchContext);

    const URLapi = apiData.urlAPI;

    const [productData, setProductData] = useState('');
    const [productSize, setProductSize] = useState([])
    var [itemHeaderStyle, setItemHeaderStyle] =  useState({});
 
    useEffect(() => {

        const query = window.location.search;
        const params = new URLSearchParams(query);
        const searchID = params.get('id');

        fetch(`${apiData.urlAPI}/produtos/${searchID.toString()}`).then(response => response.json()).then(productData => {
            
            setProductData(productData);
            setProductSize(productData.tamanho);
            setItemHeaderStyle({backgroundImage: `url('${URLapi}${productData.imagem.url}'), linear-gradient(180deg, #fff 60%, #333)`})
        
        })

    }, [])

    return (
        <div className="grid">
            <Header status={allData.data.statusFuncionamento} logo={URLapi+allData.logoData.url} whatsapp={allData.data.whatsapp} />
            <Menu grayMode={true} />
            <main className="grid-center inner-grid" id="fullitem-main">
                <div className="grid-full inner-grid" style={itemHeaderStyle} id="fullitem-header">
                    <h1 className="grid-center" id='fullitem-title'>{productData.nome}</h1>
                </div>
                <article className="grid-center">
                    <p id='fullitem-description'>{productData.descricao}</p>
                    <h3 id='fullitem__size-title'>Tamanho</h3>
                    <section id='fullitem__size-content'>
                        { 
                            productSize.map((tamanho, i) => {

                                return (
                                    <article key={i}>
                                        <div className='fullitem__content' >
                                            <div>
                                                <h3>{tamanho.nome}</h3>
                                                <p>{tamanho.descricao}</p>
                                            </div>
                                            <div>
                                                <h2>{Number(tamanho.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'} )}</h2>
                                            </div>
                                        </div>
                                        <hr />
                                    </article>
                                )
                            }) 
                        }
                    </section>
                </article>
                <Link to='/pedido' className="grid-center button-container">
                    <OrderButtom>Monte seu Pedido</OrderButtom>
                </Link>
            </main>
            <Contact endereco={allData.data.endereco} instagram={allData.data.instagram} facebook={allData.data.facebook} />
            <Footer />
        </div>
    )
}


export default FullItem;