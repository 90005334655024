import React, { useContext } from "react";
import { FetchContext } from '../../../App';

import styled from "styled-components";

const RemoveButton = styled.button `

    font-size: 10px;
    font-weight: 600;
    color: var(--branco);
    text-transform: uppercase;
    background-color: var(--vermelho-base);
    padding: 10px 10px;
    margin-left: 5px;
    border: 0px;
    border-radius: 25px;

    &:active {

    background-color: var(--vermelho-claro);

    }

`

const RemoveItem = (props) => {

    const context = useContext(FetchContext);

    function remove() {

        // pegar a lista de array

        var listaAtual = JSON.parse(localStorage.getItem('@porto-04/pedido'));

        //veriicar qual o tipo de produto

        // Pizzas

        if(props.produto === 'pizza') {

            let temNaLista = listaAtual[0].find((item) => {

                if(props.sabor2 !== false){

                    if(item.sabores[1] === undefined) {
                        return false;
                    }else if (item.nomePizza === props.nome && item.quantidade === props.quantidade && item.sabores[0].nome === props.sabor1 && item.sabores[1].nome === props.sabor2 && item.borda === props.borda) {
                        return true;
                    } else {
                        return false;
                    }
                    
                } else {

                    if (item.nomePizza === props.nome && item.quantidade === props.quantidade && item.sabores[0].nome === props.sabor1 && item.borda === props.borda) {
                        return true;
                    } else {
                        return false;
                    }

                }

            });
            
            let indexDoElemento = listaAtual[0].findIndex((item) => {

                if(temNaLista.sabores.length === 1) {
                    
                    if(item.sabores.length === 1) {
                        if (item.nomePizza === temNaLista.nomePizza && item.borda === temNaLista.borda && item.sabores[0].nome === temNaLista.sabores[0].nome){return true} else {return false};
                    } else {
                        return false
                    }

                } else {
                   
                    if(item.sabores.length === 2) {
                        if (item.nomePizza === temNaLista.nomePizza && item.borda === temNaLista.borda && item.sabores[0].nome === temNaLista.sabores[0].nome && item.sabores[1].nome === temNaLista.sabores[1].nome) {return true} else {return false};
                    } else {
                        return false
                    }
                }
                
            });

            console.log(temNaLista);
            console.log(indexDoElemento);

            temNaLista.quantidade = temNaLista.quantidade - 1;

            if(temNaLista.quantidade === 0){listaAtual[0].splice(indexDoElemento, 1);}
            localStorage.setItem('@porto-04/pedido', JSON.stringify(listaAtual));
            context.setRefreshOrder(prevState => prevState + 1);
            
            
        } else if(props.produto === 'bebida') {

            let temNaLista = listaAtual[1].find( item => {

                if(item.nome === props.nome) {
                    return true;
                } else {
                    return false;
                }
            });

            let indexDoElemento = listaAtual[1].findIndex(item => {

                console.log(item);
                console.log(temNaLista);

                if(item.nome === temNaLista.nome) {
                    return true
                } else {
                    return false
                }
            });

            console.log(indexDoElemento);

            temNaLista.quantidade = temNaLista.quantidade - 1;
            if(temNaLista.quantidade === 0){listaAtual[1].splice(indexDoElemento, 1);}
            localStorage.setItem('@porto-04/pedido', JSON.stringify(listaAtual));
            context.setRefreshOrder(prevState => prevState + 1);

        }
        
    }

    return ( 

        <RemoveButton onClick={remove}>Remover</RemoveButton>
     
    )



}

export default RemoveItem;