import React, { useContext, createContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './order.css';
import styled from 'styled-components';

import { FetchContext } from '../../App';
import apiData from '../../Fetch';

import Header from '../header/Header';
import Menu from '../menu/Menu';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

// Subcomponentes

import StepDisplay from './subcomponentes/StepDisplay';
// import DoughSelecter from './subcomponentes/DoughSelecter';
import SizeSelecter from './subcomponentes/SizeSelecter';
import CrustSelecter from './subcomponentes/CrustSelecter';
import FlavorSelecter from './subcomponentes/FlavorSelecter';
import BeverageSelecter from './subcomponentes/BeverageSelecter';
import NewItem from './subcomponentes/NewItem';
import PersonalData from './subcomponentes/PersonalData';

const OrderContext = createContext()

const StepButton = styled.button`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    margin-top: 20px;
    border: 0px;
    border-radius: 25px;
    justify-self: flex-start;

    &:active {

    background-color: var(--vermelho-claro);
}

`

const ActualPersonalData = () => {

    const userData = JSON.parse(localStorage.getItem("@porto-04/usuario"));
    var userDataString = localStorage.getItem("@porto-04/usuario");

    if(userDataString === '{}') {

        return(
            <></>
        )
    } else {
        
        return (
            <section id='usuario_dados-previos' className="content_section grid-center">
                <h3>Cadastro salvo anteriormente</h3>
                <article className='user-data'>
                    <h3>{userData.nome}</h3>
                    <p>{userData.endereco}</p>
                </article>
            </section>
        )
    }
}

const Order = () => {

    const allData = useContext(FetchContext);
    const URLapi = apiData.urlAPI;

    const [stepCounter, setStepCounter] = useState(1);
    const [size, setSize] = useState('');
    const [crust, setCrust] = useState('')
    const [itemCounter, setItemCounter] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [listaPizza, setListaPizza] = useState([]);
    const [listaBebida, setListaBebida] = useState([])

    const [finalOrder, setFinalOrder] = useState([]);

    const [novaPizza, setNovaPizza] = useState([])
   

    function next(e) {
        e.preventDefault();
        if(stepCounter < 2) {
            setStepCounter(prevState => prevState + 1);
        }
    }

    function back(e) {
        e.preventDefault();
        if(stepCounter > 1) {
            setStepCounter(prevState => prevState - 1);
        }
    }

    useEffect(() => {

        window.scrollTo(0,0)

    },[stepCounter]);

    useEffect(() => {

        setFinalOrder({
            tamanho: size,
            borda: crust,
            sabores: listaPizza,
            bebidas: listaBebida
        });

    }, [listaPizza, listaBebida, crust, size])

    function addOrder (){

        // Saltando o pedido no Local Storage
        // Puxar lista de pedidos já salva no Local Storage
        var pedidoAtual = JSON.parse(localStorage.getItem('@porto-04/pedido'));

        if(pedidoAtual[0] === []) {var pizzas = []} else {var pizzas = pedidoAtual[0]}
        if(pedidoAtual[1] === []) {var bebidas = []} else {var bebidas = pedidoAtual[1]}

        if(finalOrder.sabores.length !== 0){
        
            var nomePizza =  `Pizza - ${finalOrder.tamanho}`;
            var sabores = [];
            var borda = finalOrder.borda.nome !== undefined? finalOrder.borda.nome: 'Borda Tradicional';
            var bordaValor = finalOrder.borda.valor !== undefined? finalOrder.borda.valor: 0;
            var preco = [];
    
            finalOrder.sabores.forEach((element, i) => {
                sabores.push({nome: element.nome});
                preco.push(element.preco)
            });

            let precoFinal = Math.max(...preco);
    
            var nova = {
                nomePizza,
                sabores,
                borda,
                bordaValor,
                quantidade: 1,
                precoFinal
            }

            
            // Verificando se a pizza ja existe no array

            if(pizzas === []) {
                
                if(nova !== null) {
                    
                    pizzas.push(nova[0]);

                }

            } else {

                var naLista = pizzas.find(item => {
                    if(item.nomePizza === nova.nomePizza && nova.borda === item.borda) {
                        if(item.sabores.length !== nova.sabores.length) {
                            return false;
                        } else {
                            if(item.sabores.length === 1 && item.sabores[0].nome !== nova.sabores[0].nome) {
                                return false;
                            } else if(item.sabores.length === 1 && item.sabores[0].nome === nova.sabores[0].nome) {
                                return true;
                            } else {
                                if((item.sabores[0].nome === nova.sabores[0].nome || item.sabores[0].nome === nova.sabores[1].nome) && (item.sabores[1].nome === nova.sabores[0].nome || item.sabores[1].nome === nova.sabores[1].nome)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        }
                    } else {
                        return false
                    }
                })

                if(naLista){

                    naLista.quantidade = naLista.quantidade + 1;

                } else {

                    pizzas.push(nova)

                }
            }
    
        }

        // Organizando as bebidas
    
        if(finalOrder.bebidas.length !== 0){

            finalOrder.bebidas.map((bebida, i) => {

                var naLista = bebidas.find((item => {if(item.nome === bebida.nome) {return true;} else {return false;}}))

                if(naLista) {

                    naLista.quantidade = naLista.quantidade + 1;

                } else {

                    var precoFinal = bebida.quantidade * bebida.preco;
    
                    return bebidas.push({
                        nome: bebida.nome,
                        quantidade: bebida.quantidade,
                        precoFinal: precoFinal
                    });
                    
                }

            })

        }
        
        
        // Adicionar pizza e bebida ao local storage
        
        var pedidoFinal = [pizzas, bebidas]
        var orderString = JSON.stringify(pedidoFinal);
        localStorage.setItem('@porto-04/pedido', orderString);

    }

    function modalToogle(e) {

        e.preventDefault();
        addOrder();
        showModal === true ? setShowModal(false): setShowModal(true);
    }

    if(stepCounter === 1) {

        return (
            <div className='grid'>
                <Header status={allData.data.statusFuncionamento} logo={URLapi+allData.logoData.url} whatsapp={allData.data.whatsapp} />
                <Menu grayMode={true} />
                <main className="grid-center inner-grid" id="order-main">
                <div className="grid-full inner-grid" id="order-header__title">
                    <h1 className="grid-center" >Monte Seu Pedido</h1>
                </div>
                <p className="grid-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna orci, 
                    facilisis at porta nec, tristique hendrerit felis. Donec bibendum orci 
                    sed dui molestie, non sagittis nisi suscipit. Ut ut urna neque.
                </p>
    
                <OrderContext.Provider value={{  
                    stepCounter,
                    setStepCounter,
                    size,
                    setSize,
                    crust,
                    setCrust,
                    itemCounter,
                    setItemCounter,
                    showModal,
                    setShowModal,
                    listaPizza,
                    setListaPizza,
                    listaBebida,
                    setListaBebida,
                    finalOrder
                }}
                >
                    <StepDisplay />
                    <form className='grid-center' name='order'>
                        <SizeSelecter />
                        {/* <DoughSelecter /> */}
                        <CrustSelecter />
                        <FlavorSelecter />
                        <BeverageSelecter />
                        {showModal && <NewItem />}
                        <StepButton onClick={modalToogle} >Avançar</StepButton>
                    </form>
                </OrderContext.Provider>
                </main>
                <Contact endereco={allData.data.endereco} instagram={allData.data.instagram} facebook={allData.data.facebook} />
                <Footer />
            </div>
        )

    } else if(stepCounter === 2){

        return (
            <div className='grid'>
                <Header status={allData.data.statusFuncionamento} logo={URLapi+allData.logoData.url} whatsapp={allData.data.whatsapp} />
                <Menu grayMode={true} />
                <main className="grid-center inner-grid" id="order-main">
                <div className="grid-full inner-grid" id="order-header__title">
                    <h1 className="grid-center" >Monte Seu Pedido</h1>
                </div>
                <p className="grid-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna orci, 
                    facilisis at porta nec, tristique hendrerit felis. Donec bibendum orci 
                    sed dui molestie, non sagittis nisi suscipit. Ut ut urna neque.
                </p>
                <OrderContext.Provider value={{  
                    stepCounter,
                    setStepCounter,
                    size,
                    setSize,
                    itemCounter,
                    setItemCounter,
                    showModal,
                    setShowModal
                }}>
                    <StepDisplay />
                    <ActualPersonalData />
                    <PersonalData />
                </OrderContext.Provider>
                </main>
                <Contact endereco={allData.data.endereco} instagram={allData.data.instagram} facebook={allData.data.facebook} />
                <Footer />
            </div>
        )
    }
}

export {Order, OrderContext}