import React, {useContext, useState, useRef} from "react";
import { Link } from "react-router-dom";
import Payment from "../../payment/Payment";
import styled from 'styled-components';

import { OrderContext } from "../Order";

const StepButton = styled.button`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    margin-top: 20px;
    border: 0px;
    border-radius: 25px;
    justify-self: flex-start;

    &:active {

    background-color: var(--vermelho-claro);
}

`

const PersonalData = () => {

    const orderCtx = useContext(OrderContext);
    const [formComplete, setFormComplete] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const link = useRef(null)

    function back(e) {
        e.preventDefault();
        if(orderCtx.stepCounter > 1) {
            orderCtx.setStepCounter(prevState => prevState - 1);
        }
    }

    function setPersonalData(e) {

        e.preventDefault();
        
        var form = document.forms.dadosPessoais;
        const actualUserData = localStorage.getItem("@porto-04/usuario");


        if(form.troco !== undefined) {var troco = form.troco.value;} else {var troco = null}

        if(form.paymentType.value === ''){

            setErrorMessage('Escolha a forma de pagamento');
            setFormComplete(false);

        } else if(form.paymentType.value === 'dinheiro' && troco === '' ) {

            setErrorMessage('Insira o valor do troco');
            setFormComplete(false);

        } else {

            if(actualUserData === '{}') {
    
                if(form.nome.value === '' || form.telefone.value === '' || form.rua.value === '' || form.numero.value === '' || form.bairro.value === '' || form.cidade.value === ''){
        
                    setFormComplete(false);
        
                } else {
         
                    var userData = {
            
                        nome: form.nome.value,
                        telefone: form.telefone.value,
                        endereco: `${form.rua.value}, nº ${form.numero.value}, ${form.complemento.value}, ${form.bairro.value}, ${form.cidade.value}`,
                        pagamento: {
                            forma: form.paymentType.value,
                            troco: troco
                        }
                    }
        
                    let stringUsuario = JSON.stringify(userData);
                    localStorage.setItem('@porto-04/usuario', stringUsuario);
                    link.current.click();
            
                };

                
            } else {
                
                console.log('Já existem dados do usuário - Vou modificar a forma de pagamento ');

                let userData = JSON.parse(actualUserData);
                userData.pagamento = {
                    forma: form.paymentType.value,
                    troco: troco
                }
                let stringUsuario = JSON.stringify(userData);
                localStorage.setItem('@porto-04/usuario', stringUsuario);
                link.current.click();
    
            }

        };

    }

    return (
        <form id="dadosPessoais" className='grid-center' nome='dadosPessoais'>
            <h3 className="grid-center order-title" ><span className="order-title__number">#</span>Dados Pessoais</h3>
            <label name='nome'>Nome</label>
            <input type='text' name="nome" required/>
            <label name='telefone'>Celular</label>
            <input type='phone' name="telefone" required/>
            <label name='rua'>Rua</label>
            <input type='text' name="rua" required/>
            <label name='numero'>Número</label>
            <input type='number' name="numero" required/>
            <label name='complemento'>Complemento</label>
            <input type='text' name="complemento" required/>
            <label name='bairro'>Bairro</label>
            <input type='text' name="bairro" required/>
            <label name='cidade'>Cidade</label>
            <input type='text' name="cidade" required/>
            <h3 className="order-title" ><span className="order-title__number">#</span>Forma de Pagamento</h3>
            <Payment />
            {formComplete === false && <h3>{errorMessage} antes de avançar</h3>}
            <div id='buttons-container'>
                <StepButton onClick={back} className="grid-center">Retornar</StepButton>
                <StepButton onClick={setPersonalData}>Avançar</StepButton>
            </div>
            <Link className="grid-center" to='/carrinho' ref={link}/>
        </form>
    )
}


export default PersonalData;