import React, { useContext } from 'react';
import { FetchContext } from '../../App';
import './menu.css';
import ItemMenu from './ItemMenu/ItemMenu';
import Data from '../../Fetch';

// import { faHome, faUtensils, faGlassCheers } from "@fortawesome/free-solid-svg-icons";


const Menu = (props) => {

    const allData = useContext(FetchContext);   

    return (
        <nav id="main-menu" className="grid-center">
            {allData.categorias.sort((a, b) => {return a.posicaoNoApp < b.posicaoNoApp ? -1 : a.posicaoNoApp > b.posicaoNoApp ? 1 : 0;}).map((item, i) => {
                return (
                    <ItemMenu name={item.nome} href={`#${item.nome.replace(/\s/g, '')}`} key={i} grayMode={props.grayMode} ></ItemMenu>
                );
            })}
        </nav>
    )

}

export default Menu;