import React, {useContext} from "react";
import styled from 'styled-components';

import '../order.css';

import { OrderContext } from "../Order";

// const CloseButton = styled.button`

//     color: var(--branco);
//     font-weight: 500;
//     font-size: 1.5em;
//     text-transform: uppercase; 
//     background-color: var(--vermelho-base);
//     border: unset;
//     padding: 10px 16px;
//     border-radius: 25px;
//     margin-top: -10px;
//     margin-right: -10px;
//     position: absolute;
//     top: 0;
//     right: 0;

//     &:active {

//     background-color: var(--vermelho-claro);
// }

// `

const StepButton = styled.button`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    margin-top: 20px;
    border: 0px;
    border-radius: 25px;
    justify-self: flex-start;

    &:active {

    background-color: var(--vermelho-claro);
}

`

const NewItem = () => {
    
    const orderCtx = useContext(OrderContext);

    function next(e) {
        e.preventDefault();
        if(orderCtx.stepCounter < 3) {
            orderCtx.setStepCounter(prevState => prevState + 1);
        }
    }

    function modalToogle(e) {
        
        e.preventDefault();
        orderCtx.showModal === true ? orderCtx.setShowModal(false): orderCtx.setShowModal(true); 

    }

    // Saltando o pedido no Local Storage
    // Puxar lista de pedidos já salva no Local Storage
    var pedidoAtual = JSON.parse(localStorage.getItem('@porto-04/pedido'));

    var pizzas = pedidoAtual[0]
    var bebidas = pedidoAtual[1]
    
    return (
        <div id='modal-background'>
            <div id="new-item__modal">
                <h2>Resumo</h2>
                {
                    pizzas.map((pizza, i) => {

                        let precoAtualizado = pizza.quantidade * pizza.precoFinal;

                        return(
                            <article key={i}>
                                <div className='modal__resume-list' >
                                    <div>
                                        <h3>{pizza.quantidade}X {pizza.nomePizza}</h3>
                                        {
                                            pizza.sabores.map((item, i) => {
                                                return(
                                                    <p key={i}>{item.nome}</p>
                                                )
                                            })
                                        }
                                        <p>{pizza.borda}</p>
                                    </div>
                                    <div>
                                        <h2>{Number(precoAtualizado).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h2>
                                        {/* <RemoveItem categoria={item.categoria} tamanho ={item.tamanho} quantidade={item.quantidade} sabor1={item.sabor1} sabor2={item.sabor2} /> */}
                                    </div>
                                </div>
                                <hr />
                            </article>
                        )
                    })
                }
                
                {
                    bebidas.map((bebida, i) => {

                        let precoAtualizado = bebida.quantidade * bebida.precoFinal;

                        return(
                            <article key={i}>
                                <div className='modal__resume-list' >
                                    <div>
                                        <div>
                                            <h3>{bebida.quantidade} - {bebida.nome}</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <h2>{Number(precoAtualizado).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h2>
                                        {/* <RemoveItem categoria={item.categoria} tamanho ={item.tamanho} quantidade={item.quantidade} sabor1={item.sabor1} sabor2={item.sabor2} /> */}
                                    </div>
                                </div>
                                <hr />
                            </article>
                        )
                    })

                }
                <div className='modal-cta'>
                    <h2>Continuar Comprando?</h2>
                    <StepButton onClick={next}>Não</StepButton>
                    <StepButton onClick={modalToogle}>Sim</StepButton>
                </div>
            </div>
        </div>
    )
}

export default NewItem;