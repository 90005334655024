import React, {useContext, useState} from "react";

// import { FetchContext } from '../../../App';
// import apiData from '../../../Fetch';

import '../order.css';

import { OrderContext } from "../Order";

const ItemCounter = (props) => {
    
    const orderCtx = useContext(OrderContext);

    // preciso que ao clicar no contado ja insira o produto no array de produtos com a quantidade
    // Nome, tamanho, quantidade, preço

    const itemName = props.item;
    const itemPrice = props.price;
    const name = props.name
    
    var [itemValue, setItemValue] = useState(0);

    var produto = {
        nome: itemName,
        quantidade: 1,
        preco: itemPrice
    }

    var listaPizzaAtual = orderCtx.listaPizza;
    var listaBebidaAtual = orderCtx.listaBebida;

    if(props.counterType === 'pizza') {
        
        function addItem(e) {
            
            e.preventDefault();
    
            // altera o contador do produto

            if(orderCtx.itemCounter < 2) {
                orderCtx.setItemCounter(prevState => prevState + 1)
                setItemValue(prevState => prevState + 1);
                
                // verificando se o item já existe na lista atual
    
                var temNaLista = listaPizzaAtual.find((item) => {
                    if(item.nome === produto.nome) {
                        return true;
                    } else {
                        return false;
                    }
                })

                console.log(temNaLista);
    
                if(temNaLista) {
                   
                    //Quantidade atualizada
                    temNaLista.quantidade = temNaLista.quantidade + 1;
    
                    // atualizando o array com os dados novos
                    
                    orderCtx.setListaPizza(orderCtx.listaPizza) ;
                    console.log(orderCtx.listaPizza);

                } else {

                    orderCtx.setListaPizza(prevState => [...prevState, produto]);
                    console.log(orderCtx.listaPizza);
    
                }
            }
        }
    
        function removeItem(e) {
    
            e.preventDefault();
    
            if(orderCtx.itemCounter > 0){
                if(itemValue > 0) {
                    orderCtx.setItemCounter(prevState => prevState -1)
                    setItemValue(prevState => prevState - 1);

                    // procurar item na lista de array                   

                    var temNaLista = listaPizzaAtual.find((item) => {
                        if(item.nome === produto.nome) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    console.log(temNaLista);

                    if(temNaLista) {

                        //Quantidade atualizada
                        temNaLista.quantidade = temNaLista.quantidade - 1;

                        if(temNaLista.quantidade === 0) {

                            var index = listaPizzaAtual.indexOf(temNaLista);
                            listaPizzaAtual.splice(index, 1);
                            orderCtx.setListaPizza(listaPizzaAtual);
                            console.log(orderCtx.listaPizza);


                        } else {

                            // atualizando o array com os dados novos
                            
                            orderCtx.setListaPizza(orderCtx.listaPizza) ;
                            console.log(orderCtx.listaPizza);

                        }

                    }

                }
            }
    
        }

        if(orderCtx.size !== '') {
            
            return (
                <div className='item-counter__container'>
                    <button onClick={removeItem}>-</button>
                    <h3>{itemValue}</h3>    
                    <button onClick={addItem}>+</button>
                </div>
            )
        } else {

            return (
                
                <div className='item-counter__container'>
                    <h6 className='size-warning'>Escolha o Tamanho</h6>
                </div>
            )
        }

    } else {

        function addItem(e) {
            
            e.preventDefault();
    
            setItemValue(prevState => prevState + 1);
            
            // verificando se o item já existe na lista atual
    
            var temNaLista = listaBebidaAtual.find((item) => {
                if(item.nome === produto.nome) {
                    return true;
                } else {
                    return false;
                }
            })

            if(temNaLista) {

                //Quantidade atualizada
                temNaLista.quantidade = temNaLista.quantidade + 1;

                // atualizando o array com os dados novos
                
                orderCtx.setListaBebida(orderCtx.listaBebida) ;

            } else {

                orderCtx.setListaBebida(prevState => [...prevState, produto]);

            }
            
        }
    
        function removeItem(e) {
    
            e.preventDefault();
    
            if(itemValue > 0) {
                
                setItemValue(prevState => prevState - 1);

                // procurar item na lista de array

                var temNaLista = listaBebidaAtual.find((item) => {
                    if(item.nome === produto.nome) {
                        return true;
                    } else {
                        return false;
                    }
                });

                if(temNaLista) {

                    //Quantidade atualizada
                    temNaLista.quantidade = temNaLista.quantidade - 1;

                    if(temNaLista.quantidade === 0) {

                        var index = listaBebidaAtual.indexOf(temNaLista);
                        listaBebidaAtual.splice(index, 1);
                        orderCtx.setListaBebida(listaBebidaAtual);

                    } else {

                        // atualizando o array com os dados novos
                        
                        orderCtx.setListaBebida(orderCtx.listaBebida) ;


                    }
                }
            }
            
            console.log(listaBebidaAtual);
        }

        return (
            <div className='item-counter__container'>
                <button onClick={removeItem}>-</button>
                <h3>{itemValue}</h3>
                <button onClick={addItem}>+</button>
            </div>
        )

    }
    

}


export default ItemCounter;