import React, {useContext} from "react";

import { FetchContext } from '../../../App';
// import apiData from '../../../Fetch';

import '../order.css';

// import { OrderContext } from "../Order";
import ItemCounter from "./ItemCounter";


const BeverageSelecter = () => {

    const allData = useContext(FetchContext);
    // const URLapi = apiData.urlAPI;
    const categorias = allData.categorias
    const bebidas = categorias.find((item) => {if(item.nome == "Bebidas") {return true} else {return false}})
    // const orderCtx = useContext(OrderContext);

    if(bebidas !== undefined) {

        return (

            <section id="beverage-section">
                <div className='order-section__title-container'>
                    <div>
                        <h3>Selecione a Bebida</h3>
                        {/* <p>Escolha até 2 opções</p> */}
                    </div>
                    {/* <h4>Obrigatório</h4> */}
                </div>
                <div className='order-section__selecter-container'>
                    {
                        bebidas.produtos.map((item, i) => {

                            return (
                                <article className='' key={i}>
                                    <div>
                                        <label className='selecter__description-price'>
                                            <h3>{item.nome}</h3>
                                            <p>{item.descricao}</p>
                                        </label>
                                        <div>
                                            {
                                                item.tamanho.map((tamanho, e) => { 
                                                    return (
                                                        <div className='beverage-size__selecter' key={e}> 
                                                            <p className='price' ><strong>{tamanho.nome}</strong> <br /> {tamanho.valor.toLocaleString('pt-BR', {style: 'currency', currency: "BRL"})}</p>
                                                            <ItemCounter item={`${item.nome} ${tamanho.nome}`} price={tamanho.valor} fullcounter={true} name={`beverage${e}`} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                </article>
                            )
                        })
                    }
                </div>
            </section>
        )

    } else {

        return (
            <></>
        )

    }


}


export default BeverageSelecter;