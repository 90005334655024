import './contact.css';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons'; 

const GoogleButton = styled.a`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    border: 0px;
    border-radius: 25px;

    &:active {

    background-color: var(--vermelho-claro);
}

`

const Contact = (props) => {

    return (

        <section id="main-contact" className="grid-center inner-grid">
            <article className="grid-center" >
                <div id="contact-address">
                    <h4><FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço</h4>
                    <p>{props.endereco}</p>
                    <GoogleButton href={`https://www.google.com.br/maps/place/${props.endereco}`}>Google Maps</GoogleButton>
                </div>
                <div id="contact-social_media">
                    <h4><FontAwesomeIcon icon={faShareAlt} /> Redes Sociais</h4>
                    <div id='social-icons__container'>
                        {props.instagram === ''?'':<a href={props.instagram}><FontAwesomeIcon icon={faInstagram} size='2x' /></a>}
                        {props.facebook === ''?'':<a href={props.facebook}><FontAwesomeIcon icon={faFacebookSquare} size='2x' /></a>}
                    </div>
                </div>
            </article>
        </section>
    )

}

export default Contact;