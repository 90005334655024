require('dotenv').config()

// Dados Referentes ao restaurante 

if (process.env.NODE_ENV === 'production') {

    var Data = {
        
        retaurantData: fetch('https://porto04.rodolfomarques.vps-kinghost.net/restaurantes').then(response => response.json()).then(retaurantData => {return retaurantData}),
        categoryData:  fetch('https://porto04.rodolfomarques.vps-kinghost.net/categorias').then( response => response.json()).then( categoryData => {return categoryData}),
        urlAPI: 'https://porto04.rodolfomarques.vps-kinghost.net',
        localURL: window.location.hostname
        
    }
    
} else {
    
    var Data = {
        
        retaurantData: fetch('http://localhost:1337/restaurantes').then(response => response.json()).then(retaurantData => {return retaurantData}),
        categoryData:  fetch('http://localhost:1337/categorias').then( response => response.json()).then( categoryData => {return categoryData}),
        urlAPI: 'http://localhost:1337',
        localURL: 'http://localhost:3000/'
        
    }

}

export default Data; 