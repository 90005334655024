import React, {useContext} from "react";

import { FetchContext } from '../../../App';

import '../order.css';

import { OrderContext } from "../Order";


const CrustSelecter = () => {

    const allData = useContext(FetchContext);
    // const URLapi = apiData.urlAPI;
    const categorias = allData.categorias
    const bordas = categorias.find((item) => {if(item.nome == "Bordas") {return true} else {return false}});

    const orderCtx = useContext(OrderContext);

    if(bordas !== undefined) {

        return (

            <section id="">
                <div className='order-section__title-container'>
                    <div>
                        <h3>Selecione a Borda</h3>
                        <p>Escolha 1 opção</p>
                    </div>
                    {/* <h4>Obrigatório</h4> */}
                </div>
                <div className='order-section__selecter-container'>
                    {
                        bordas.produtos.map((item, i) => {
                            return(
                                <article className='' key={i}>
                                    <div>
                                        <label className='selecter__description-price'>
                                            <h3>{item.nome}</h3>
                                            <p>{item.descricao}</p>
                                            <p className='price'>+ {item.tamanho[0].valor.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'})}</p>
                                        </label>
                                        <input type='radio' name='crust' value={item.tamanho[0].valor} onChange={() => {
                                            
                                            let borda = {nome: item.nome, valor: item.tamanho[0].valor};
                                            orderCtx.setCrust(borda);

                                            }}
                                        />
                                    </div>
                                    <hr />
                                </article>
                            )
                        })
                    }
                </div>
            </section>
        )

    } else {

        return (<></>)
    }
}


export default CrustSelecter;