import React from 'react';
import './banner.css';

const Banner = (props) => {

    return (
        <section id="banner-section" className="grid-center">
            <img src={props.src} alt="#" />
        </section>
    )
}

export default Banner;