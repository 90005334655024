import { FetchContext } from '../../App';
import '../../App.css';
import { useContext } from 'react';
import Header from '../header/Header';
import Banner from '../banner/Banner';
import Menu from '../menu/Menu';
import Products from '../products/Products';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

import Data from '../../Fetch';


function MainPage() {

  const allData = useContext(FetchContext)

  const URLapi = Data.urlAPI;

  return (
    <div className="grid">
      <Header status={allData.data.statusFuncionamento} logo={URLapi+allData.logoData.url} whatsapp={allData.data.whatsapp} />
      <main className="grid-center inner-grid">
        <Banner src={URLapi+allData.bannerData.url} />
        <Menu />
        <Products />
      </main>
      <Contact endereco={allData.data.endereco} instagram={allData.data.instagram} facebook={allData.data.facebook} />
      <Footer />
    </div>
  );
}

export default MainPage;
