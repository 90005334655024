import React from 'react';
import './itemMenu.css';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemMenu = (props) => {

    return (

        <a className={props.grayMode === true? 'menu-item menu-item-gray': 'menu-item'} href={props.href}>
            {/* <FontAwesomeIcon icon={props.icon} size={props.size} className="menu-item__icon" /> */}
            {props.name}
        </a>
    )
}

export default ItemMenu;