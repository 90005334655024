import React, {useContext} from 'react';
import { OrderContext } from '../Order';
import '../order.css'


const StepDisplay = () => {

    const orderContext = useContext(OrderContext);
    const Counter = orderContext.stepCounter

    if(Counter === 1) {

        return (
    
            <div className='grid-center' id="steps-titles">
                <h3 className="step__order-title"><span className="order-title__number-active">1</span>Pedidos</h3>
                <h3 className="step__order-title"><span className="order-title__number">2</span>Dados Pessoais</h3>
                <h3 className="step__order-title"><span className="order-title__number">3</span>Fechar</h3>
            </div> 
        )

    } else if(Counter === 2) {

        return (
    
            <div className='grid-center' id="steps-titles">
                <h3 className="step__order-title"><span className="order-title__number-active">1</span>Pedidos</h3>
                <h3 className="step__order-title"><span className="order-title__number-active">2</span>Dados Pessoais</h3>
                <h3 className="step__order-title"><span className="order-title__number">3</span>Fechar</h3>
            </div> 
        )

    } else if (Counter === 3) {

        return (
    
            <div className='grid-center' id="steps-titles">
                <h3 className="step__order-title"><span className="order-title__number-active">1</span>Pedidos</h3>
                <h3 className="step__order-title"><span className="order-title__number-active">2</span>Dados Pessoais</h3>
                <h3 className="step__order-title"><span className="order-title__number-active">3</span>Fechar</h3>
            </div> 
        )

    }

}


export default StepDisplay;