import React from 'react';
import {Link} from 'react-router-dom';
import './product.css';
import apiData from '../../../Fetch';



// Nome, descrição, preço padrão, preço promocional, em promoção?

const Product = (props) => {

    console.log(apiData.urlAPI)

    let imageDiv = {

        width: '100%',
        minWidth: 150,
        minHeight: 150,
        backgroundImage: `url(${apiData.urlAPI+props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: 30,
        flex: '1 1 0px'
    
    }
    
    return (
        

        <Link to={{pathname: '/produto', search:`?id=${props.itemID}`}}>
            <div className="product-card">
                <div style={imageDiv} className='product-image'>
                {/* div que armazena a imagem */}
                </div>
                <article className="product-content" >
                    <h4 className="product-title">{props.name}</h4>
                    <p className="product-description" >{props.description}</p>
                    {props.promotionalPrice === null ? 
                        <h4 className="product-last_price" >{Number(props.price).toLocaleString('pt-BR',{style: 'currency', currency: 'BRL'})}</h4> : 
                        <> 
                            <p className="product-first_price" >{Number(props.price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</p> 
                            <h4 className="product-last_price" >{Number(props.promotionalPrice).toLocaleString('pt-BR',{style: 'currency', currency: 'BRL'})}</h4> 
                        </>
                    }
                    
                </article>
            </div>
        </Link>
    )

}


export default Product;