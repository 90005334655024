import React, { useContext, useEffect } from 'react';
import './checkout.css';

import Header from '../header/Header';
import Menu from '../menu/Menu';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import RemoveItem from '../order-new/subcomponentes/RemoveItem';

import apiData from '../../Fetch';
import { FetchContext } from '../../App';

import styled from 'styled-components';

const SendButton = styled.button`

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    margin-bottom: 20px;
    border: 0px;
    border-radius: 25px;
    justify-self: center;

    &:active {

    background-color: var(--vermelho-claro);
}

`
const userData = JSON.parse(localStorage.getItem("@porto-04/usuario"));
const URLapi = apiData.urlAPI;

const Resume = () => {

    const allData = useContext(FetchContext)

    let pedido = JSON.parse(localStorage.getItem('@porto-04/pedido'));
    var pizzas = pedido[0];
    var bebidas = pedido[1];


    useEffect(()=>{
        console.log('pedido atualizado');
    }, [allData.refreshOrder])

    let stringPedido = localStorage.getItem('@porto-04/pedido');

    if (stringPedido === '[[],[]]') {

        return(
            <section className="content_section grid-center">
                <h3>Não há produtos na sua lista de pedido</h3>
            </section>
        )

    } else {

        return(
            <section className="content_section grid-center">
                { 
                    pizzas.map((item, i) => {

                        let precoAtualizado = item.quantidade * item.precoFinal;
                        
                        return (
                            <article key={i}>
                                <div className='resume-list__item' >
                                    <div>
                                        <span className="order-title__number">{item.quantidade}</span>
                                        <div>
                                            <h3>{item.nomePizza}</h3>
                                            { 
                                                item.sabores.map((item, i) => {
                                                    return(
                                                        <p key={i}>{item.nome}</p>
                                                    )
                                                })
                                            }
                                            {item.sabor2 !== '' && <p>{item.sabor2}</p>}
                                            {item.borda !== '' && <p>Borda: {item.borda}</p> }
                                        </div>
                                    </div>
                                    <div>
                                        <h2>{Number(precoAtualizado).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'} )}</h2>
                                        <RemoveItem produto='pizza' nome={item.nomePizza} quantidade={item.quantidade} sabor1={item.sabores[0].nome} sabor2={item.sabores[1] !== undefined && item.sabores[1].nome} borda={item.borda} />
                                    </div>
                                </div>
                                <hr />
                            </article>
                        )
                    })
                }
                { 
                    bebidas.map((item, i) => {

                        let precoAtualizado = item.quantidade * item.precoFinal;

                        return (
                            <article key={i}>
                                <div className='resume-list__item' >
                                    <div>
                                        <span className="order-title__number">{item.quantidade}</span>
                                        <div>
                                            <h3>{item.nome}</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <h2>{Number(precoAtualizado).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'} )}</h2>
                                        <RemoveItem produto='bebida' nome={item.nome} quantidade={item.quantidade} />
                                    </div>
                                </div>
                                <hr />
                            </article>
                        )
                    })
                }
            </section>
        )
    }
}


const Order = () => {

    const allData = useContext(FetchContext);
    const URLapi = apiData.urlAPI;

    let pedido = JSON.parse(localStorage.getItem('@porto-04/pedido'));

    var pizzas = pedido[0];
    var bebidas = pedido[1];

    var stringPizzas = [];
    var stringBebidas = []

    pizzas.map(item => {

        let sabor1 = item.sabores[0].nome
        if(item.sabores[1] !== undefined) {
            var sabor2 = item.sabores[1].nome;
        } else {
            var sabor2 = '';
        };

        var pizza = `*${item.nomePizza}*\n`+
        `*Sabor 1:* ${sabor1}\n`+
        `*Sabor 2:* ${sabor2}\n`+
        `${item.borda}\n`+
        `*Quantidade*: ${item.quantidade}\n`

        stringPizzas.push(pizza);

    })

    bebidas.map(item => {

        var bebida = `*${item.nome}* \n*Quantidade:* ${item.quantidade}`;
        stringBebidas.push(bebida);
    })

    
    userData.pagamento ?? (
        userData.pagamento = {troco: null}
        )
        
    if(userData.pagamento.troco !== null || userData.pagamento.troco !== undefined) {var troco = Number(userData.pagamento.troco).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} else {var troco = "Não é necessário"}
    

    function sendToWhatsApp(e) {

        e.preventDefault();

        // var numWhats = `+55${allData.data.whatsapp}`
        var numWhats = `+5583987221561`


var texto = `Pedido Porto 04

CLIENTE
========================

Nome: ${userData.nome}
Endereço: ${userData.endereco}
Contato: ${userData.telefone}

PEDIDO
========================

Pizzas:
${stringPizzas.join('\n')}

Bebidas:
${stringBebidas.join('\n')}

*Forma de Pagamento:* ${userData.pagamento.forma}
*Troco para:* ${troco}
`

        var link =`https://api.whatsapp.com/send?phone=${numWhats}&text=${encodeURIComponent(texto)}`;

        var button = document.createElement('a');
        button.target = '_blank';
        button.href = link;

        button.click();

        localStorage.setItem('@porto-04/pedido', '[[],[]]');
        
    }

    return (
    <div className="grid">
        <Header status={allData.data.statusFuncionamento} logo={URLapi+allData.logoData.url} whatsapp={allData.data.whatsapp} />
        <Menu grayMode={true} />
        <main className="grid-center inner-grid" id="fullitem-main">
            <div className="grid-full inner-grid" id="order-header__title">
                <h1 className="grid-center">Monte Seu Pedido</h1>
            </div>
            <p className="grid-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna orci, 
                facilisis at porta nec, tristique hendrerit felis. Donec bibendum orci 
                sed dui molestie, non sagittis nisi suscipit. Ut ut urna neque.
            </p>
            <div className='grid-center' id="steps-titles">
                <h3 className="step__order-title"><span className="order-title__number-active">1</span>Pedidos</h3>
                <h3 className="step__order-title"><span className="order-title__number-active">2</span>Dados Pessoais</h3>
                <h3 className="step__order-title"><span className="order-title__number-active">3</span>Fechar</h3>
            </div>
            <div className='order-section__title-container grid-center'>
                <div>
                    <h3>Seu Pedido</h3>
                </div>
            </div>
            <Resume />
            <div className='order-section__title-container grid-center'>
                <div>
                    <h3>Seus Dados</h3>
                </div>
            </div>
            <section className="content_section grid-center">
                <article className='user-data'>
                    <h3>{userData.nome}</h3>
                    <p>{userData.endereco}</p>
                    <p>Forma de Pagamento: {userData.pagamento.forma}</p>
                    <p>Troco para: {userData.pagamento.troco !== null? Number(userData.pagamento.troco).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}): "Não é necessário" }</p>
                </article>
            </section>
            <SendButton onClick={sendToWhatsApp} className="grid-center">Enviar Pedido</SendButton>
            </main>
            <Contact endereco={allData.data.endereco} instagram={allData.data.instagram} facebook={allData.data.facebook} />
            <Footer />
        </div>
    )
}

export default Order;